.boxTitle {
  font-size: 24px;
  font-weight: 400;

  margin: 0;
  margin-bottom: 15px;
  padding: 0;
}

.boxDescription {
  font-size: 16px;
  font-weight: 400;

  margin: 0;
  margin-bottom: 15px;
  padding: 0;
}
