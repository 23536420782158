@import '~styles/shared';

.main {
  width: 100vw;
  min-height: 100vh;
}

.view {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  padding-top: 100px;
  padding-left: 270px;
}
