@import '~styles/shared';

.linkStyle {
  text-decoration: none;
  color: none;
}

.listItem {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: 100%;
  height: 80px;
  margin: 0;
  padding: 0;
  transition: background-color 0.2s;
  background-color: $white;

  a {
    text-decoration: none;
  }

  &:hover {
    cursor: pointer;
    background-color: $gray;
  }

  &.active {
    background-color: $gray;
  }
}

.activeBar {
  width: 7px;
  height: 100%;
  margin-right: 30px;
  transition: opacity 0.2s;
  opacity: 0;
  background-color: $black;
  &.active {
    opacity: 1;
  }
}

.iconsContainer {
  position: relative;

  width: 45px;
  height: 45px;
  margin-right: 30px;
}

.menuIcon {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: auto;
  height: 45px;
  transition: opacity 0.2s;
  opacity: 0;

  &.active {
    z-index: 99;

    opacity: 1;
  }
}

.menuItemText {
  font-size: 18px;
  font-weight: 300;

  margin: 0;
  padding: 0;
  transition: color 0.2s;
  text-decoration: none;
  color: $darkGray;

  &.active {
    font-weight: 600;

    color: $black;
  }
}
