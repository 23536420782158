@import '~styles/shared';

.wrapper {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  margin: 0;
  padding: 0;
  padding-right: 35px;
  padding-left: 270px;
}

.line {
  position: absolute;
  right: 35px;
  bottom: 0;

  width: calc(100% - 305px - 35px);
  height: 1px;
  margin: 0;
  padding: 0;
  color: $black;
}

.row {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 0 35px;
  background-color: $gray;
}

.pageTitle {
  font-size: 32px;
  font-weight: 500;

  margin: 0;
  padding: 0;
  padding-left: 35px;
}

.buttonGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
  margin: 0;
  margin-left: auto;
  padding: 0;
}

.settingsButton {
  font-size: 21px;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-right: 50px;
  padding: 0;
  color: $black;
  border: 0;
  outline: none;
  background-color: rgba($white, 0);
  box-shadow: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;

    .settingsIcon {
      transform: rotate(20deg);
    }
  }

  .settingsIcon {
    width: auto;
    height: 30px;
    margin-right: 17px;
    transition: transform 0.3s;
    transform: rotate(0deg);
  }
}

.logoutButton {
  font-size: 21px;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  transition: color 0.2s;
  color: $black;
  border: 0;
  outline: none;
  background-color: rgba($white, 0);
  box-shadow: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: $red;
  }
}

.orgNameUserContainer {
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  justify-content: center;
  width: auto;
  margin-right: 30px;

  .orgName {
    width: auto;
    margin: 0;
    padding: 0;
  }
  .userEmail {
    width: auto;
    margin: 0;
    padding: 0;
  }
}

.statusContainer {
  position: absolute;
  z-index: 99;
  right: 35px;
  bottom: -28px;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0 15px;
  transition: opacity 0.2s;
  opacity: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: $green;
  img {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }
  p {
    font-size: 12px;
    text-transform: uppercase;

    margin: 0;
    padding: 0;
    color: $white;
  }
}
.show {
  opacity: 1;
}

.langContainer {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: center;
  margin-left: 50px;
}

.label {
  font-weight: 500;

  margin: 0;
  margin-right: 15px;
}
