.innerPageWrapper {
  overflow-y: scroll;
  height: calc(100vh - 100px);
  padding: 35px;
  padding-top: 10px;
}

.flexRows {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  justify-content: space-between;
}

.shadowedBox {
  padding: 10px;
  border: 1px solid rgba($black, 0);
  border-radius: 10px;
  background-color: $white;
  box-shadow: 0 3px 6px rgba($black, 0.16);
}
