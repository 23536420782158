$black: #000000;
$white: #ffffff;
$green: #94d34e;
$red: #d65d4a;

/**
 * Involve Colors
 * -------------
 */

$dark: #16171d;
$gray: #f8f7f7;
$blue: #1e73be;
$darkGray: #959595;
$facebook: #3c5a99;
$instagram: #833ab4;
$delta: #53b4bf;
$deltaDark: #240f54;
