@import '~styles/shared';

.main {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: $gray;

  .loader {
    width: 100px;
    height: 100px;
  }
}
