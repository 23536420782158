@import '~styles/shared';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  overflow-y: scroll;
  align-items: center;
  flex-flow: column nowrap;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 270px;
  height: 100vh;
  padding: 0;
  background-color: $white;
}

.logo {
  width: 112px;
  height: auto;
  margin: 30px 0;
}

.line {
  position: absolute;
  top: 98px;
  left: 15px;

  width: calc(100% - 30px);
  height: 1px;
  margin: 0;
  padding: 0;
  padding: 0;
  color: $black;
}

.title {
  font-size: 22px;
  font-weight: 600;

  width: 100%;
  margin: 40px 0;
  padding: 0;
  text-align: center;
}

.menu {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
