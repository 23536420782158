@import 'modules/colorVariables';
@import 'modules/generalVariables';
@import 'modules/responsiveMixins';
@import 'modules/layoutClasses';
@import 'modules/formElements';
@import 'modules/typography';

html {
}

body {
  font-family: $mainFont;

  margin: 0;
  padding: 0;
}
