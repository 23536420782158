.fade-enter {
  z-index: 1;

  opacity: 0;
}

.fade-enter.fade-enter-active {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.page-exit {
  opacity: 0;
}
