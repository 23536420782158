@import '~styles/shared';

.container {
}

.select {
  font-size: 14px;

  min-width: 200px;
  height: 40px;
  padding: 0 10px;
  transition: background-color 0.2s, border 0.2s;
  border: 2px solid rgba($dark, 0);
  border-radius: 5px;
  background-color: $white;
  box-shadow: 0;

  &:focus {
    border: 2px solid rgba($dark, 0.5);
    outline: none;
  }
}
.option {
}
